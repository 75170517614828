<template>
  <v-chart class="echart" :option="option" :autoresize="true" :loading="loading" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, GaugeChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  components: {
    VChart
  },
  props: ['value', 'loading', 'color'],
  watch: {
    value: {
      immediate: true,
      handler() {
        this.updateChartOption()
      },
    },
  },
  data() {
    return {
      option: {
        color: [
          this.color ? this.color : "#5470c6"
        ],
        emphasis: {
          disabled: true
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['90%', '100%'],
            avoidLabelOverlap: true,
            label: {
              show: true,
              fontSize: 60,
              position: 'center',
              formatter: '{c}',
              fontWeight: 'bold',
              rich: {
                "<style_name>": {
                  verticalAlign: "middle",
                  padding: [22, 0, 0, 0]
                }
              }
            },
            labelLine: {
              show: true
            },
            data: [
              { value: this.value, name: '', show: true }
            ]
          }
        ]
      }
    };
  },
  methods: {
    updateChartOption() {
      this.option.series[0].data[0].value = this.value
    }
  }
};
</script>

<style scoped>
.echart {
  height: 400px;
}
</style>
