<template>
  <v-chart class="echart" :option="option" :autoresize="true" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, GaugeChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  components: {
    VChart
  },
  props: ['value', 'loading'],
  watch: {
    value: {
      immediate: true,
      handler() {
        this.updateChartOption()
      },
    },
  },
  data() {
    return {
      option: {
        color: ["#7065ea"],
        series: [
          {
            type: 'gauge',
            progress: {
              show: true,
              width: 20
            },
            axisLine: {
              lineStyle: {
                width: 20
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              length: 15,
              lineStyle: {
                width: 2,
                color: '#999'
              }
            },
            axisLabel: {
              distance: 25,
              color: '#999',
              fontSize: 14
            },
            anchor: {
              show: true,
              showAbove: true,
              size: 25,
              itemStyle: {
                borderWidth: 5
              }
            },
            title: {
              show: false
            },
            detail: {
              valueAnimation: true,
              fontSize: 60,
              formatter: (value) => value,
              offsetCenter: [0, '70%']
            },
            data: [
              {
                value: this.value ? this.value : 0
              }
            ],
            radius: "100%"
          }
        ]
      }
    };
  },
  methods: {
    updateChartOption(){
      this.option.series[0].data[0].value = this.value ? this.value : 0;
      if(this.value > 80) {
        this.option.color = ['red']
      } else if(this.value > 60) {
        this.option.color = ['yellow']
      } else {
        // this.option.color = ['#7065ea']
        this.option.color = ['green']
      }
    }
  }
};
</script>

<style scoped>
.echart {
  height: 400px;
}
</style>
