<template>
  <div id="dashboard">
    <span class="local-time">{{ local_time }}</span>

    <b-row>
      <b-col>
        <b-card :title="$t('Parking Lots')">
          <PieEchart :value.sync="parkingLots.length" color="#7065ea" v-if="!loadingParkingLots" />
        </b-card>
      </b-col>
      <b-col>
        <b-card :title="$t('Spaces')">
          <PieEchart :value.sync="spaces.length" color="#7065ea" v-if="!loadingSpaces" />
        </b-card>
      </b-col>
      <b-col>
        <b-card :title="$t('Occupancy') + ', %'">
          <GaugeEchart :value.sync="occupancyPercent" v-if="!loadingSpaces && !loadingOccupancys" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BRow, BCol } from "bootstrap-vue";
import GaugeEchart from "@/components/GaugeEchart"
import PieEchart from "@/components/PieEchart"

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BCol,
    GaugeEchart,
    PieEchart,
  },
  computed: {
    g_selectedPrincipal() {
      return this.$store.state.global.selectedPrincipal;
    },
    g_selectedParkingLot() {
      return this.$store.state.global.selectedParkingLot;
    },
    parkingLots() {
      const filtered = this.$store.state.parkingLot.parkingLots.filter((p) => {
        return (
          (this.g_selectedParkingLot == null ||
            p.id == this.g_selectedParkingLot?.id) &&
          (this.g_selectedPrincipal == null ||
            p.principal?.id == this.g_selectedPrincipal?.id)
        );
      });
      return filtered;
    },
    spaces() {
      const filtered = this.$store.state.space.spaces.filter((sc) => {
        return (
          (this.g_selectedParkingLot == null ||
            sc.parkingLot?.id == this.g_selectedParkingLot?.id) &&
          (this.g_selectedPrincipal == null ||
            sc.parkingLot?.principal?.id == this.g_selectedPrincipal?.id)
        );
      });
      return filtered;
    },
    occupancys() {
      const filtered = this.$store.state.device.occupancys.filter((sc) => {
        return (
          (this.g_selectedParkingLot == null ||
            sc.space?.parkingLot?.id == this.g_selectedParkingLot?.id) &&
          (this.g_selectedPrincipal == null ||
            sc.space?.parkingLot?.principal?.id == this.g_selectedPrincipal?.id)
        );
      });
      return filtered;
    },
    occupancyPercent() {
      const percent = parseInt(Math.round(this.occupancys.length / this.spaces.length * 100))
      return percent;
    },
    loadingParkingLots() {
      return this.$store.state.parkingLot.loadingParkingLots;
    },
    loadingSpaces() {
      return this.$store.state.space.loadingSpaces;
    },
    loadingOccupancys() {
      return this.$store.state.device.loadingOccupancys;
    },
  },
  data() {
    return {
      local_time: null,
      timer: null
    }
  },
  async created() {
    await this.$store.dispatch("parkingLot/get", this.$bvToast);
    await this.$store.dispatch("space/get", this.$bvToast);
    await this.$store.dispatch("device/getOccupancys", this.$bvToast);
  },
  mounted() {
    this.local_time = this.$moment().format("HH:mm:ss MM.DD.YYYY")
  },
  unmounted() {
  }
};
</script>

<style>
#dashboard .card-title {
  font-size: 20px;
}

.local-time {
  position: absolute;
  z-index: 1;
  right: 31px;
  margin-top: -45px;
  font-size: 20px;
}
</style>
